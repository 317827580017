import React from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { navigate } from 'gatsby';
import './header.scss';
import { HeaderData } from '../../../data/db';

export default function Header() {
  return (
    <section className="header">
      <Container>
        <Row>
          <Col className="col-12 col-md-5 header-content">
            <span className="space">
              <span className="heading">
                <h1 className="main-heading">
                  {HeaderData[0].heading1}

                </h1>
                {/* <span className="heading2">
                    {' '}
                    {HeaderData[0].heading2}
                  </span>

                </h1>
                <h1 className="heading3 main-heading">{HeaderData[0].heading3}</h1> */}
              </span>
              <p className="text">
                {HeaderData[0].text}
              </p>
              <Button className="infenox-button" variant="primary" role="link" onClick={() => { navigate('/solutions'); }}>{HeaderData[0].buttonLabel}</Button>
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
